import * as actionTypes from '../actions/actionTypes.js';

export function initiateBridgerPayDeposit(data: any): any {
  return {
    type: actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT,
    data,
  };
}

export function bridgerPayInitiatedSuccess(data: any): any {
  return {
    type: actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT_SUCCESS,
    data,
  };
}

export function bridgerPayInitiatedFailed(): any {
  return {
    type: actionTypes.deposit.INITIATE_BRIDGER_PAY_DEPOSIT_FAILED,
  };
}

export const oktoCreatePaymentCode = (amount: number, bonusId: number | null) => {
  return {
    type: actionTypes.deposit.OKTO_CREATE_PAYMENT_CODE,
    amount: amount,
    bonusId: bonusId,
  };
};

export const oktoReceivedPaymentCode = (data: any) => {
  return {
    type: actionTypes.deposit.OKTO_RECEIVED_PAYMENT_CODE,
    data: data,
  };
};

export const oktoGetCustomer = () => {
  return {
    type: actionTypes.deposit.OKTO_GET_CUSTOMER,
  };
};

export const oktoReceivedCustomer = (data: any) => {
  return {
    type: actionTypes.deposit.OKTO_RECEIVED_CUSTOMER,
    data: data,
  };
};

export const oktoReset = () => {
  return {
    type: actionTypes.deposit.OKTO_RESET,
  };
};

export const aircashGetPaymentLink = (amount: number, bonusId: number | null) => {
  return {
    type: actionTypes.deposit.AIRCASH_REQUEST_PAYMENT_LINK,
    amount: amount,
    bonusId: bonusId,
  };
};

export const receivedAircashPaymentLink = (data: any) => {
  return {
    type: actionTypes.deposit.AIRCASH_RECEIVED_PAYMENT_LINK,
    response: data,
  };
};
