import React from 'react';

import './index.scss';

const PageLoader = () => {
  return (
    <div className="page-loader w-100 h-100">
      <img src={window.config.loaderImage} alt="loader" />
      <div className="text">{window.config.loaderText}</div>
    </div>
  );
};

export default PageLoader;
