import React from 'react';
import styled from 'styled-components';
import Logger from '../../utils/logger';

const logger = Logger('HtmlTagFactory');

export const defaultProps = {
  className: '',
  styleText: '',
};

type HtmlTag = {
  className: string;
  $styleText: string;
  style: any;
  children?: React.ReactNode; // best, accepts everything React can render
};

const HtmlTagDiv = styled.div<HtmlTag>((props) => props.$styleText);
const HtmlTagInput = styled.input<HtmlTag>((props) => props.$styleText);
const HtmlTagTextarea = styled.textarea<HtmlTag>((props) => props.$styleText);
const HtmlTagLabel = styled.label<HtmlTag>((props) => props.$styleText);

export const HtmlTagFactory = (tag: string) =>
  function HtmlTag(componentProps: HtmlTag) {
    const props = React.useMemo(() => {
      const tmpProps = { ...defaultProps, ...componentProps };
      delete tmpProps.children;
      return JSON.parse(JSON.stringify(tmpProps));
    }, [componentProps]);

    //logger.debug(`create tag ${props.tag}`);

    if (tag === 'div') return <HtmlTagDiv {...props}>{componentProps.children}</HtmlTagDiv>;
    if (tag === 'input') return <HtmlTagInput {...props}>{componentProps.children}</HtmlTagInput>;
    if (tag === 'textarea') return <HtmlTagTextarea {...props}>{componentProps.children}</HtmlTagTextarea>;
    if (tag === 'label')
      return (
        <HtmlTagLabel $styleText={props.styleText} style={props.style} className={props.className}>
          {componentProps.children}
        </HtmlTagLabel>
      );

    logger.error(`unknown tag ${props.tag}`);
    return null;
  };
