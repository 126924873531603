// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { RootState } from '../index';

const DEBUG = false;

type FetchConfigProps = {
  ids: string[];
};

type FetchConfigResult = {
  data: any[];
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

const apiUrl = window.config.dataSourceApiUrl;

export const fetchElements = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'fetchElements/query',
  async ({ ids }, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      const response = await axios.post(
        `${apiUrl}/resolve/elements`,
        {
          ids: ids,
        },
        {
          headers: {
            Authorization: 'Bearer ' + state.authentication.access_token,
          },
        },
      );

      if (response.data) {
        return { data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch elements",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface ConfigReducer {
  items: any;
  loading: boolean;
  error: any;
}

export const dataElementsSlice = createSlice({
  name: 'dataElements',
  initialState: <ConfigReducer>{
    items: {},
    loading: false,
    error: null,
  },
  reducers: {
    clearDataElements: (state) => {
      state.items = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchElements.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          state.items = { ...state.items, ...action.payload.data };
        }
        DEBUG && console.timeEnd('fetchElements.query');
        DEBUG && console.log('fetchElements.fulfilled', action.payload);
      })
      .addCase(fetchElements.pending, (state) => {
        DEBUG && console.time('fetchElements.query');
        state.loading = true;
      })
      .addCase(fetchElements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        DEBUG && console.log('fetchElements.rejected', action.payload);
      });
  },
});

export const { clearDataElements } = dataElementsSlice.actions;

export default dataElementsSlice.reducer;
