import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../store';
import { logout } from '../../../modules/casino/store/actions/login';
import ResponseErrorMessages from '../../../modules/casino/store/errors/ResponseErrorMessages';

import './index.scss';

const AccountProblem = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.authentication);
  const navigate = useNavigate();

  const onClose = () => {
    dispatch(logout());
    navigate('/');
  };
  const supportHandler = () => {
    dispatch(logout());
    navigate('/customer-support/contact');
  };

  const getMessageAdditions = (code: number) => {
    let htmlMessage = null;

    if (23 === code) {
      if (authentication.accountProblemMessage) {
        const exclusionInterval = authentication.accountProblemMessage.split('-');
        htmlMessage = (
          <React.Fragment>
            <span>
              {t('Self exclusion period') + ' ' + t('from') + ' '}
              {moment.unix(exclusionInterval[0]).format('DD/MM/YYYY')}
              {' ' + t('to') + ' '}
              {moment.unix(exclusionInterval[1]).format('DD/MM/YYYY')}
            </span>
            <br />
            <span>{t('If you want to cancel this action, please contact support.')}</span>
          </React.Fragment>
        );
      }
    }

    return htmlMessage;
  };

  if (!authentication.accountProblemModal) return null;

  return (
    <Modal isOpen={true} toggle={onClose}>
      <ModalHeader toggle={onClose}>{t(ResponseErrorMessages.get(authentication.accountProblemCode))}</ModalHeader>
      <ModalBody>{getMessageAdditions(authentication.accountProblemCode)}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onClose}>
          {t('Close')}
        </Button>{' '}
        <Button color="secondary" onClick={supportHandler}>
          {t('Open support')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AccountProblem;
