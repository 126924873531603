import { put } from 'redux-saga/effects';
import * as actions from './../actions';
import ClientAPI from './../../ClientAPI/ClientAPI';
import * as paths from './../../ClientAPI/paths';

export function* resetPasswordSendEmailSaga(action) {
  const axios = ClientAPI.getInstance();
  let email = action.email;
  try {
    const response = yield axios({
      url: paths.resetPassword.RESET_PASSWORD,
      method: 'post',
      data: {
        mail: email,
      },
    });

    if (!response) {
      throw new Error('[ERROR] Send Email response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Send Email response has no 'result' property");
    }

    yield put(actions.resetPasswordSendEmailResponse(response.result));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* resetPasswordSaga(action) {
  const axios = ClientAPI.getInstance();
  const storeState = ClientAPI.getStore().getState();
  let newPassword = action.newPassword;
  let token = storeState.resetPassword.token;
  try {
    const response = yield axios({
      url: paths.resetPassword.RESET_PASSWORD,
      method: 'post',
      data: {
        token: token,
        password: newPassword,
      },
    });

    if (!response) {
      throw new Error('[ERROR] Reset Password response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Reset Password response has no 'result' property");
    }

    yield put(actions.resetPasswordSendNewPasswordResponse(response.result));
  } catch (error) {
    console.log(error);
    return;
  }
}
