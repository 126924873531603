import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import Jackpot from './jackpot';
import { PageDataContext, getProjectDataFromContext } from '../../components/utils/PageDataProvider';

export const defaultProps = {
  className: '',
  styleText: '',
};

const OdometerDiv = styled.div((props) => props.$styleText);
const DEBUG = false;

export const Odometer = (componentProps) => {
  let props = componentProps;

  const pageDataContext = React.useContext(PageDataContext);

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  let onClick = null;
  if (props.properties && props.properties.onClick && typeof props.properties.onClick === 'function') {
    onClick = props.properties.onClick;
  } else if (
    componentProps.properties &&
    componentProps.properties.onClick &&
    typeof componentProps.properties.onClick === 'function'
  ) {
    onClick = componentProps.properties.onClick;
  }

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  let { value, digits, decimals, initialOffset, counterInitialSteps, counterRefreshSteps, isJackpot, storageKey } =
    props.properties;

  digits = isNaN(Number(digits)) ? 0 : Number(digits);
  decimals = isNaN(Number(decimals)) ? 0 : Number(decimals);
  value = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  initialOffset = isNaN(Number(initialOffset)) ? 5 : Number(initialOffset);
  counterInitialSteps = isNaN(Number(counterInitialSteps)) ? 30 : Number(counterInitialSteps);
  counterRefreshSteps = isNaN(Number(counterRefreshSteps)) ? 10 : Number(counterRefreshSteps);

  if (value != null) {
    try {
      const digNumber = Math.trunc(value);
      if (digits < digNumber.toString().length) {
        digits = digNumber.toString().length;
      }
    } catch (err) { }
  }

  const stylesState = React.useMemo(() => {
    let styles = {};

    try {
      const data = getProjectDataFromContext(pageDataContext);

      const digitsWrapperNode = data[props.linkedNodes['digit']];
      const digitsInnerWrapperNode = data[digitsWrapperNode.nodes[0]];
      const digitNode = data[digitsInnerWrapperNode.nodes[0]];
      const decimalsWrapperNode = data[props.linkedNodes['decimal']];
      const decimalsInnerWrapperNode = data[decimalsWrapperNode.nodes[0]];
      const decimalNode = data[decimalsInnerWrapperNode.nodes[0]];
      const radixNode = data[props.linkedNodes['radix']];

      styles = {
        digitsWrapperClassName: digitsWrapperNode.props.className,
        digitsWrapper: digitsWrapperNode.props.styleText ?? '',
        digitsInnerWrapperClassName: digitsInnerWrapperNode.props.className,
        digitsInnerWrapper: digitsInnerWrapperNode.props.styleText ?? '',
        digitClassName: digitNode.props.className,
        digit: digitNode.props.styleText ?? '',
        decimalsWrapperClassName: decimalsWrapperNode.props.className,
        decimalsWrapper: decimalsWrapperNode.props.styleText ?? '',
        decimalsInnerWrapperClassName: decimalNode.props.className,
        decimalsInnerWrapper: decimalNode.props.styleText ?? '',
        decimalClassName: decimalNode.props.className,
        decimal: decimalNode.props.styleText ?? '',
        radixClassName: radixNode.props.className,
        radix: radixNode.props.styleText ?? '',
      };
    } catch (err) {
      /*noop*/
    }
    return styles;
  }, [pageDataContext, props.linkedNodes]);

  if (!isVisible) return null;

  if (isNaN(parseInt(value, 10))) return null;

  DEBUG && console.log({ initialOffset, counterInitialSteps, counterRefreshSteps });

  return (
    <OdometerDiv
      className={props.className ?? ''}
      $styleText={props.styleText}
      onClick={onClick}
      style={props.style}
      {...extraDataProps}
    >
      <Jackpot
        value={value}
        digits={digits}
        fractionalDigits={decimals}
        styles={stylesState}
        initialOffset={initialOffset}
        counterInitialSteps={counterInitialSteps}
        counterRefreshSteps={counterRefreshSteps}
        isJackpot={!!isJackpot}
        storageKey={storageKey ? storageKey : ''}
        key={storageKey ?? null}
      />
    </OdometerDiv>
  );
};

export default React.memo(Odometer, equalWithoutChildren);
