import React from 'react';
import styled from 'styled-components';
import { extend } from 'lodash-es';

import craftJsParser from '../../components/utils/craftJsParser';
import { useAppSelector } from '../../store';
import { LayoutContext } from '../common/LayoutContext';
import { PageDataContext, getProjectDataFromContext } from '../../components/utils/PageDataProvider';

export const defaultProps = {
  className: '',
  styleText: '',
  properties: {},
  visibility: {},
};

const TabsContainerDiv = styled.div((props) => props.$styleText);

const detectStuck = (styleText) => {
  const containerRef = React.useRef(null);
  const [isStucked, setStucked] = React.useState(false);

  const callback = (entries) => {
    const [entry] = entries;
    setStucked(!entry.isIntersecting);
  };

  React.useEffect(() => {
    let observer = null;
    if (styleText && styleText.includes('position:sticky') && containerRef.current != null) {
      let rootMargin = '0px';
      const re = RegExp(/top:(.*?)px/i);
      const matches = re.exec(styleText);

      if (matches) console.log(matches);
      observer = new IntersectionObserver(callback);
      observer.observe(containerRef.current);
    }

    return () => {
      if (observer && containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef, styleText]);

  return [containerRef, isStucked];
};

export const TabsContainer = (props) => {
  const pageDataContext = React.useContext(PageDataContext);
  const context = React.useContext(LayoutContext);
  let layoutStates = [];
  let selectedLayout = '';

  if (context) ({ selectedLayout, layoutStates } = context);

  const switchToLayout = (newLayout) => (e) => {
    e.stopPropagation();
    if (context && typeof context.switchLayout === 'function') {
      context.switchLayout(newLayout);
    }
  };

  const [sentinelRef, isStucked] = detectStuck(props.styleText);

  const tabsContent = [];
  try {
    const data = getProjectDataFromContext(pageDataContext);

    (layoutStates ?? []).forEach((state, i) => {
      const item = craftJsParser({
        craftState: data,
        rootNodeId: props.linkedNodes[state.value],
        pageId: props.refType === 'layout' ? null : props.refId,
        pageLayoutId: props.refType === 'layout' ? props.refId : null,
        pageType: props.refType,
        extendProps: {
          'data-layout': state.value,
          className: state.value === selectedLayout ? 'active' : '',
          onClick: switchToLayout(state.value),
        },
      });
      tabsContent.push(
        React.cloneElement(item, { key: i })
      );
    });
  } catch (err) {
    console.error(err);
    return null;
  }

  return (
    <React.Fragment>
      <div style={{ width: 0, height: 0, opacity: 0 }} ref={sentinelRef}></div>
      <TabsContainerDiv
        data-is-node="true"
        data-stuck={isStucked}
        className={props.className ?? ''}
        $styleText={props.styleText}
        style={props.style}
      >
        {tabsContent}
      </TabsContainerDiv>
    </React.Fragment>
  );
};
