import { takeLatest, put } from 'redux-saga/effects';

import { deposit } from '../actions/actionTypes';
import ClientAPI from './../../ClientAPI/ClientAPI';
import {
  bridgerPayInitiatedFailed as initiateBridgerPayDepositFailed,
  bridgerPayInitiatedSuccess as initiateBridgerPayDepositSuccess,
  oktoReceivedCustomer,
  oktoReceivedPaymentCode,
  receivedAircashPaymentLink,
} from '../actions/deposit';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker';

function* initiateBridgerPayDepositSaga(action: any): any {
  const axios = ClientAPI.getInstance();
  let data = action.data;
  const pac = PlayerAbuseChecker.getInfo();
  data = {
    ...pac,
    ...data,
  };
  try {
    const response = yield axios({
      url: '/api/profile/initiateBridgerPayDeposit',
      method: 'post',
      data: data,
    });
    console.log(response);
    if (!response.status) {
      throw new Error('[Bridger Pay] No status response');
    }

    if (!('OK' === response.status)) {
      throw new Error('[Bridger Pay] Status NOK');
    }
    if (!response.result) {
      throw new Error('[Bridger Pay] Empty result');
    }
    if (!response.result.cashierKey || !response.result.cashierToken) {
      throw new Error('[Bridger Pay] Empty result');
    }
    yield put(initiateBridgerPayDepositSuccess(response.result));
  } catch (e) {
    console.log('[Bridger Pay Init]', e);
    yield put(initiateBridgerPayDepositFailed());
  }
}

export function* requestOktoCreatePaymentCodeSaga(action: any) {
  const axios = ClientAPI.getInstance();
  try {
    let data = {
      amount: action.amount * 100,
      currencyId: 1,
    };

    if (action.bonusId) {
      // @ts-ignore
      data.bonusId = action.bonusId;
    }

    const pac = PlayerAbuseChecker.getInfo();
    data = {
      ...pac,
      ...data,
    };

    const platformType = window.config.cordova
      ? window.config.native_platform === 'ios'
        ? 3
        : 4
      : window.config.platformType ?? 5;
    // @ts-ignore
    data.platformType = platformType;

    // @ts-ignore
    const response = yield axios({
      url: '/api/pay-checkout/okto-create-payment-code',
      method: 'post',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Request Okto Create Payment Code response is empty!');
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('result')) {
      throw new Error("[ERROR] Request Okto Create Payment Code response has no 'result' property");
    }

    yield put(oktoReceivedPaymentCode(response.result));
  } catch (error) {
    yield put(oktoReceivedPaymentCode({ error: true }));
    console.log(error);
    return;
  }
}

export function* oktoGetCustomerSaga(action: any) {
  const axios = ClientAPI.getInstance();
  try {
    // @ts-ignore
    const response = yield axios({
      url: '/api/pay-checkout/okto-get-customer',
      method: 'GET',
    });

    if (!response) {
      throw new Error('[ERROR] Request Okto GET Customer response is empty!');
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('result')) {
      throw new Error("[ERROR] Request Okto GET CUSTOMER response has no 'result' property");
    }

    yield put(oktoReceivedCustomer(response.result));
  } catch (error) {
    return;
  }
}

export function* requestAircashPaymentLinkSaga(action: any) {
  const axios = ClientAPI.getInstance();
  const state = ClientAPI.getStore().getState();
  try {
    const pac = PlayerAbuseChecker.getInfo();
    const platformType = window.config.cordova
      ? window.config.native_platform === 'ios'
        ? 3
        : 4
      : window.config.platformType;

    const data = {
      amount: action.amount * 100,
      bonus_id: action.bonusId,
      currencyCode: state.wallet.currency,
      currencyId: 1,
      languageCode: state.application.language,
      clientId: window.config.clientId,
      ...pac,
      platformType: platformType,
    };

    // @ts-ignore
    const response = yield axios({
      url: '/api/pay-checkout/aircash-checkout',
      method: 'post',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Payment Checkout response is empty!');
    }
    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('result')) {
      throw new Error("[ERROR] Payment Checkout response has no 'result' property");
    }

    yield put(receivedAircashPaymentLink(response.result));
  } catch (error) {
    yield put(receivedAircashPaymentLink({ error: true }));
    return;
  }
}

export default function* watchDeposit() {
  yield takeLatest(deposit.INITIATE_BRIDGER_PAY_DEPOSIT, initiateBridgerPayDepositSaga);
  yield takeLatest(deposit.OKTO_CREATE_PAYMENT_CODE, requestOktoCreatePaymentCodeSaga);
  yield takeLatest(deposit.OKTO_GET_CUSTOMER, oktoGetCustomerSaga);

  yield takeLatest(deposit.AIRCASH_REQUEST_PAYMENT_LINK, requestAircashPaymentLinkSaga);
}
