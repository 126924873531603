import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';

import * as actionTypes from '../../../casino/store/actions/actionTypes';
import { loadedCalendarStatus } from '../actions/calendar';
import { calendarConstants } from '../actions/constants';
import getStore from '..';

export const apiUrl = window.config.betsApiUrl + '/api/calendar/v1/player';

let lastRequest = 0;

function* loadCalendarStatusSaga() {
  const store = getStore();
  if (!store) return;
  const { authentication } = store.getState();

  if (!authentication.access_token) {
    return;
  }

  const headers = {
    Authorization: 'Bearer ' + authentication.access_token,
  };

  const now = +new Date();
  if (!(now - lastRequest > 2000)) {
    return;
  }
  lastRequest = now;

  try {
    const resp = yield axios.get(apiUrl + '/status', { headers });
    yield put(loadedCalendarStatus(resp.data[0]));
  } catch (err) {
    yield put(loadedCalendarStatus(null)); // called so we can mark loaded as false
    console.log('loadWinterStatusSaga[ERR]:', err);
  }
}

function* openPrizeSaga({ id }) {
  if (!id) return;
  const { authentication } = getStore().getState();

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  const headers = {
    Authorization: 'Bearer ' + authentication.access_token,
  };

  try {
    const resp = yield axios.get(apiUrl + '/prize/open/' + id, { headers });
    yield put(loadedCalendarStatus(resp.data[0]));
  } catch (err) {
    yield put(loadedCalendarStatus());
    console.log('openPrizeSaga[ERR]:', err);
  }
}

function* collectPrizeSaga({ id }) {
  if (!id) return;
  const { authentication } = getStore().getState();

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  const headers = {
    Authorization: 'Bearer ' + authentication.access_token,
  };

  const now = +new Date();
  if (!(now - lastRequest > 2000)) {
    return;
  }
  lastRequest = now;

  try {
    const resp = yield axios.get(apiUrl + '/prize/collect/' + id, { headers });
    yield put(loadedCalendarStatus(resp.data[0]));
  } catch (err) {
    yield put(loadedCalendarStatus());
    console.log('openPrizeSaga[ERR]:', err);
  }
}

export default function* watchWinterSaga() {
  yield takeEvery(actionTypes.profile.RECEIVED_ACCOUNT, loadCalendarStatusSaga);
  yield takeEvery(calendarConstants.LOAD, loadCalendarStatusSaga);
  yield takeEvery(calendarConstants.OPEN_PRIZE, openPrizeSaga);
  yield takeEvery(calendarConstants.COLLECT_PRIZE, collectPrizeSaga);
}
