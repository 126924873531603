import React from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';

import { useAppSelector } from '../store';
import { hardodedJackpotResp } from '@/_temp/hardcodedJackpotResp';
const apiUrl = window.config.dataSourceApiUrl;

const cache: any = {
  loading: {},
  ref: {},
};
window._gds_cache = cache;

type GameInfoProps = {
  dsId?: string;
  filterKey?: string;
  filterValue?: string;
  limit?: number | null;
};

const digestMessage = async (message: string) => {
  const data = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-1', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
};

const genericDataSource = ({ dsId, filterKey, filterValue, limit }: GameInfoProps) => {
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);

  const [state, setState] = React.useState<{ data: any; loading: boolean; loaded: boolean; digestKey: string }>({
    data: {
      id: null,
      element_type: null,
      meta: null,
      data: null,
    },
    loading: true,
    loaded: false,
    digestKey: '',
  });

  React.useEffect(() => {
    if (dsId)
      digestMessage(JSON.stringify({ dsId, filterKey, filterValue })).then((resp) => {
        setState((v) => ({
          ...v,
          digestKey: resp,
        }));
      });
  }, [dsId, filterKey, filterValue]);

  //console.log('digestKey', state.digestKey);

  const processResponse = (
    stillMounted: boolean,
    resp: any,
    dsId: string,
    filterKey: string | undefined,
    filterValue: string | undefined,
    digestKey: string,
  ) => {
    let data = null;
    if (resp?.data?.data) {
      data = resp.data.data;
    }

    const final = {
      id: dsId,
      data: data,
      element_type: resp?.data?.element_type,
      meta: resp?.data?.meta,
      filterKey,
      filterValue,
    };

    if (cache.ref[digestKey]) cache[digestKey] = final;

    if (stillMounted) {
      setState({
        data: final,
        loading: false,
        loaded: true,
        digestKey,
      });
    }

    return { [digestKey]: final };
  };

  React.useEffect(() => {
    let stillMounted = true;

    const digestKey = state.digestKey;

    if (authenticationToken && dsId && digestKey) {
      if (cache.ref[digestKey] == null) cache.ref[digestKey] = 0;
      cache.ref[digestKey] += 1;

      if (cache[digestKey]) {
        const data = cloneDeep(cache[digestKey]);
        setState({
          data: data,
          loading: false,
          loaded: true,
          digestKey,
        });
        return;
      } else if (cache.loading[digestKey]) {
        cache.loading[digestKey].promise
          .then((data: any) => {
            if (!stillMounted) return;
            if (!data[digestKey]) return;

            setState({
              data: data[digestKey],
              loading: false,
              loaded: true,
              digestKey,
            });
          })
          .catch((e: Error) => {
            console.log(e);
            setState((v) => ({ ...v, loading: false }));
          });
        return;
      } else {
        cache.loading[digestKey] = {};
        cache.loading[digestKey].promise = new Promise((resolve, reject) => {
          const filter = [];
          if (filterKey && filterValue) {
            filter.push({ name: filterKey, value: filterValue });
          }

          const postData: any = {
            id: dsId,
            filter: filter,
          };
          if (limit != null) {
            postData.limit = limit;
          }

          axios
            .post(`${apiUrl}/resolve/source`, postData, {
              headers: {
                Authorization: 'Bearer ' + authenticationToken,
              },
            })
            .then((resp) => {
              if (dsId === 'all_jackpots_api' && window.config.environment === 'staging') {
                const hard = JSON.parse(JSON.stringify(hardodedJackpotResp));

                hard.data = hardodedJackpotResp.data.filter((jackpot: any) => jackpot.group === filterValue);
                resolve(processResponse(stillMounted, { data: hard }, dsId, filterKey, filterValue, digestKey));
                return;
              }

              resolve(processResponse(stillMounted, resp, dsId, filterKey, filterValue, digestKey));
            })
            .catch((e: Error) => {
              console.log(e);
              setState((v) => ({ ...v, loading: false }));
              reject(e);
            });
        });
      }
    }

    return () => {
      stillMounted = false;
    };
  }, [dsId, state.digestKey, filterKey, filterValue, authenticationToken]);

  return state;
};

export default genericDataSource;
