import * as actionTypes from './actionTypes';
import { resetOneRequest } from './requestManager';
import * as actions from './index';

export const reinitializeApp = () => ({
  type: actionTypes.application.REINITIALIZE,
});

export const appInitialize = () => ({
  type: actionTypes.application.INITIALIZE,
});

export const setTopMenuHeight = (heightClass) => {
  return {
    type: actionTypes.application.SET_MENU_HEIGHT,
    menuHeight: heightClass,
  };
};

export const setPageCode = (code) => {
  return {
    type: actionTypes.application.SET_PAGE_CODE,
    code: code,
  };
};

export const getPromotions = (promotions) => {
  return {
    type: actionTypes.application.GET_PROMOTIONS,
  };
};

export const getEligibleBonuses = (bonuses) => {
  return {
    type: actionTypes.application.GET_ELIGIBLE_BONUSES,
  };
};

export const getBonusAvailable = (bonuses) => {
  return {
    type: actionTypes.application.GET_BONUS_AVAILABLE,
  };
};

export const claimBonus = (bonusId, force) => {
  return {
    type: actionTypes.application.CLAIM_BONUS,
    bonusId: bonusId,
    force: force,
  };
};

export const setPromotions = (promotions) => {
  return {
    type: actionTypes.application.SET_PROMOTIONS,
    promotions: promotions,
  };
};

export const setPreEuro = (tickets) => {
  return {
    type: actionTypes.application.SET_PREEURO,
    tickets: tickets,
  };
};

export const setEligibleBonuses = (bonuses) => {
  return {
    type: actionTypes.application.SET_ELIGIBLE_BONUSES,
    bonuses: bonuses,
  };
};

export const setAwardBonus = (bonusId, data) => {
  return {
    type: actionTypes.bonuses.SET_AWARD_BONUS,
    bonusId: bonusId,
    data: data,
  };
};

export const setAvailableBonuses = (bonuses) => {
  return {
    type: actionTypes.application.SET_AVAILABLE_BONUSES,
    bonuses: bonuses,
  };
};

export const setBonuses = (bonuses) => {
  return {
    type: actionTypes.bonuses.SET,
    bonuses: bonuses,
  };
};

export const getBonuses = (data) => {
  return {
    type: actionTypes.bonuses.GET,
    data,
  };
};

export const getBonusCalculator = (bonusId, depositAmount, currency) => {
  return {
    type: actionTypes.bonuses.GET_BONUS_CALCULATOR,
    bonusId: bonusId,
    depositAmount: depositAmount,
    currency: currency,
  };
};
export const resetBonusClaimStatus = () => {
  return (dispatch) => {
    dispatch(resetOneRequest('get-bonus-available'));
    dispatch({
      type: actionTypes.application.RESET_BONUS_CLAIM_STATUS,
    });
    dispatch(getBonusAvailable());
    dispatch(getEligibleBonuses());
    dispatch(actions.freeSpinsFetch());
    dispatch(actions.freeBetsFetch());
  };
};
export const bonusClaimed = (result) => {
  return {
    type: actionTypes.application.BONUS_CLAIMED,
    result: result,
  };
};

export const bonusCalculatorReceived = (result) => {
  return {
    type: actionTypes.application.BONUS_CLAIMED,
    result: result,
  };
};

export const setPageType = (pageType) => {
  return {
    type: actionTypes.application.SET_PAGE_TYPE,
    pageType: pageType,
  };
};

export const appRouteChanged = (pathname) => {
  return {
    type: actionTypes.application.ROUTE_CHANGED,
    pathname: pathname,
  };
};

export const appResized = () => {
  return {
    type: actionTypes.application.RESIZE,
  };
};

export const setPageClassName = (pageClassName) => {
  return {
    type: actionTypes.application.SET_PAGE_CLASSNAME,
    className: pageClassName,
  };
};

export const globalWidgets = (show) => {
  return {
    type: show ? actionTypes.application.SHOW_GLOBAL_WIDGET : actionTypes.application.HIDE_GLOBAL_WIDGET,
  };
};

export const changeLanguage = (language) => {
  return {
    type: actionTypes.application.CHANGE_LANGUAGE,
    language: language,
  };
};
export const changeAccountLanguage = (language) => {
  return {
    type: actionTypes.application.CHANGE_ACCOUNT_LANGUAGE,
    language: language,
  };
};

export const setLastProductId = (productId) => {
  return {
    type: actionTypes.application.SET_LAST_PRODUCT_ID,
    productId: productId,
  };
};

export const redeemCode = (code) => {
  return {
    type: actionTypes.application.REDEEEM_CODE,
    code: code,
  };
};

export const redeemCodeReset = () => {
  return {
    type: actionTypes.application.REDEEEM_CODE_RESET,
  };
};
export const redeemCodeResponse = (response) => {
  return {
    type: actionTypes.application.REDEEEM_CODE_RESPONSE,
    response: response,
  };
};

export const logEvent = (event) => {
  return {
    type: actionTypes.application.PUSH_EVENT_LOG,
    event: event,
  };
};

export const setRouterHistory = (data) => {
  return {
    type: actionTypes.application.SET_ROUTER_HISTORY,
    data
  };
};
